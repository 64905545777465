.activitiesSection3RowItemLeft {
			/* max-width: 545px;
			max-height: 570px; */
	flex: 0 0 500px;
}

.activitiesSection3RowItemLeft img {
	max-width: 100%;
	
}

.activitiesSection3 {
	margin-top: 80px;
	margin-bottom: 20px;
	display: flex;
	
}

.activitiesSection3Column {
	display: flex;
	flex-direction: column;
	max-width: 90%;
}

.activitiesSection3Row {
	display: flex;
	margin-bottom: 84px;
}

.activitiesSection3RowItemRight {
	margin-left: 20px;
	padding: 20px 64px;
	flex: 1 1 auto;
}

.activitiesSection3RowItemRight h2 {
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	text-transform: uppercase;
	color: #3F4964;
	margin-bottom: 64px;
}

.activitiesSection3RowItemRight p {
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 30px;
	text-align: justify;
	color: #3F4964;
	margin-bottom: 30px;
}

@media screen and (max-width:1350px) {
    .activitiesSection3RowItemRight {
		padding: 20px 20px 20px 20px};
}

@media screen and (max-width:1100px) {
    .activitiesSection3RowItemLeft {
		flex: 0 0 400px;
	}
	.activitiesSection3RowItemRight {
		padding: 0 20px 20px 20px;
	}
}

@media screen and (max-width:800px) {
    .activitiesSection3RowItemLeft {
		flex: 0 0 350px;
	order: 2;}
}


@media screen and (max-width:800px) {
    .activitiesSection3Row {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.activitiesSection3RowItemRight h2 {
		text-align: center;
	}
}

/* Headers */

.contact_container {
	width: 1396px;
	max-width: 1396px;
	min-width: 63%;
	margin: 0px auto;
	display: flex;
	justify-content: center;
}

.contact_section1_1 {
	max-width: 63%;
    border-style: solid;
    border-color: #DBCEAB;
    border-width: 2px;
    margin: 0px auto;
    display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: space-between;
	flex-grow: 1;
}

@media screen and (max-width:950px) {
    .contact_section1_1 {
		min-width: 90%!important;
	}

	.contact_section1_3 h3 {
		margin-left: 10px!important;
		margin-right: 10px!important;
	}
}



@media screen and (max-width:410px) {
    .contact_section1_3 h3 {
		font-size: 18px!important;
	}
	.contact_section1_3 {
		padding: 0px 0px;
	}

	.contact_section1_1 {
		max-width: 90%;
	}
}

.contact_section1_2 {
	background: rgba(255, 255, 255, 0.5);
  	margin: 10px;
}
.contact_section1_3 {
	background: #fff;
	opacity: 1;
	margin: 30px;
	padding: 33px;
}
.contact_section1_3 h3 {
	font-size: 45px;
	text-align: center;
	text-transform: uppercase;
	color: #3F4964;
	margin-left: 50px;
	margin-right: 50px;
	}


