.pricesSection2 {
	padding: 80px 60px 50px 60px;
	max-width: 90%;
	margin: 0 auto;
	border: 2px solid #DBCEAB;
	
}

.pricesSection2row {
	padding: 76px 0px 90px 0px;
	
}

.pricesSection2 h1 {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 70px;
}

.pricesSection2 p {
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 30px;
	text-align: justify;
	margin-top: 30px;
}

.pricesSection3Title h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 70px;
	line-height: 1.9rem;
}

/* Section3 */

.pricesSection3 {
	
	display: flex;
}

.pricesSection3Items {
	display: flex;
	flex-direction: column;
	max-width: 90%;
}

@media screen and (max-width:1290px) {
    .itemList {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.itemListDescription {
		text-align: center;
	}
}

@media screen and (max-width:480px) {
    H3 {
		font-size: 25px!important;
	}
	
}

@media screen and (max-width:1280px) {
    .pricesSection3Title h2 {
		margin-bottom: 20px;
	}
	
}

.pricesSection3_row {
	display: flex;
	margin: 0px 0px 80px 0px;
	justify-content: space-between;
	flex-wrap:wrap;
}

.pricesSection3_column {
	flex: 1 1 30%;
	padding: 0px;
	flex-wrap:wrap;
	
}

.pricesSection3_column:last-child {
	border: 2px solid #DBCEAB;
}

.pricesSection3_column_item {
	padding: 50px;
	max-width: 100%;
}

.itemLabel {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.itemLabel h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	text-transform: uppercase;
}

.itemPrice {
	text-align: center;
	font-size: 20px;
}

.itemList {
	margin-top: 30px;
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 30px;
	text-align: justify;
	list-style-type: square;
}

.itemListDescription {
	list-style-type: square;
}

.pricesSection4Items {
	padding: 20px 0px 80px 0px;
	margin: 0 auto;
}

.pricesTable {
	width: 100%;
}

.pricesSection4Items {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 90%;
}

table {
	min-width: 100%;
	 border-bottom:2px solid #DBCEAB;
}

th, td {
  padding: 15px;
  text-align: left;
}

th {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 700;
}

tr:nth-child(even) {
  background-color: #DBCEAB;
}

