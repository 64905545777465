.galleryBody {
	position: relative;
	padding-top: 20px;
	padding-bottom: 20px;
}

.galleryMain {
	max-width: 100%;
	height: 500px;
  	padding: 100px 0 100px 0;
  	overflow: hidden;
  	overflow-x: hidden;
  	white-space: nowrap;
	
}

.galleryMain img {
	height: 300px;
	width: calc(100% / 4);
	object-fit: cover;
	margin-left: 13px;
}

.galleryMain img:first-child {
	margin-left: 0;
}

.img-main:hover {
	transform: scale(1.7);
}

.galleryMain img:first-child:hover {
	transform: scale(1.7) translateX(100px);
}

.galleryMain img:last-child:hover {
	transform: scale(1.7) translateX(-100px);
}

.left.gallery-button {
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translateY(-50%);
  width: 60px;
}



.icon {
	max-width: 100%;
	height: 60px;
}

.right.gallery-button {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  width: 60px;
}

.left.gallery-button:hover,
.right.gallery-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 1500px) {
	.galleryMain img{
		width: calc(100% / 3);
	}
	
}

@media screen and (max-width: 900px) {
	.galleryMain img{
		width: calc(100% / 2);
	}
	
}

@media screen and (max-width: 600px) {
	.galleryMain img{
		width: 100%;
	}

	.galleryBody {
		padding: 0;
	}

}

#slider {
  scroll-behavior: smooth;
}
