/* Reset CSS + Settings start */
* {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: Roboto;
  line-height: 1;
  font-size: 17px;
  color: #3f4964;
}
input,
button,
textarea {
  font-family: inherit;
}

button {
  cursor: pointer;
  background: none;
  color: #3f4964;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
  color: #3f4964;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  font-family: Garamond;
}

.wrapper {
  min-height: 100%;
  overflow: hidden;
}

/* container and media */

._container {
  max-width: 1396px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Reset END */

:root {
  --index: calc(1vw + 1vh);
}

/* NavigationBar CSS */

/* navBarTop */
.navBarTop {
  font-weight: 700;
  font-size: 25px;
  line-height: 100%;
  text-transform: uppercase;
  height: 70px;
  background-color: #e4ebf3;
  align-items: center;
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 100%;
  z-index: 20;
}

.menuBar {
  display: flex;
  align-items: center;
  height: 100%;
  width: 90%;
}

.logo {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
}

.langSelector {
  width: 40px;
}

.lang_hidded {
  display: none;
}

/* navBarBottom */
.navBarBottom {
  margin-top: 70px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  justify-content: center;
}

.navigation {
  width: 62%;
  display: flex;
}

.navElement {
  flex: 0 1 33.333%;
}

.navElement1 {
  text-align: left;
}

.navElement2 {
  text-align: center;
}

.navElement3 {
  text-align: right;
}

.navBarBottom button {
  text-transform: uppercase;
}

.navMobile {
  display: none;
}

.mobile_controls {
  display: none;
}

@media screen and (max-width: 950px) {
  header .navBarBottom {
    visibility: hidden;
    margin-top: 20px;
  }
  .navElement3 {
    display: none;
  }
  .navElement2 {
    display: none;
  }
  .navElement {
    flex: 0 1 auto;
  }
  .mobile_controls {
    display: block;
  }
  .menuBar {
    justify-content: space-between;
  }
  .mobile_controls_hidded {
    display: none;
  }
  .navMobileOpened {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    font-size: 30px;
    margin: 0 auto;
  }
  .navBarTopOpened .navElement1 {
    position: absolute;
    left: 5%;
    top: 5%;
    font-size: 1.5rem;
  }
  .navBarTopOpened .mobile_controls {
    position: absolute;
    right: 5%;
    top: 5%;
  }
}
.navBarTopOpened {
  height: 100vh;
  transition: 0.5s;
}
.navMobileOpened {
  display: block;
}

/* Homepage CSS */
.section1 {
  margin-top: 0px;
  padding-top: 130px;
  padding-bottom: 170px;
  position: relative;
  justify-content: center;
  display: flex;
}

@media (max-width: 767px) {
  .section1 {
    background-image: none !important;
    background-color: #f4f4f4;
  }
}

.section1_1 {
  width: 62%;
  border-style: solid;
  border-color: #dbceab;
  border-width: 2px;
  margin: 0px auto;
  display: flex;
}

.section1_2 {
  background: rgba(255, 255, 255, 0.5);
  margin: 10px;
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
}

.section1_3 {
  width: 100%;
  background: #fff;
  opacity: 1;
  margin: 30px;
  padding: 70px;
}

.section1_3 h1 {
  margin-bottom: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #a0a7b4;
}

.section1_3 h3 {
  font-size: 45px;
  line-height: 59px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  color: #3f4964;
  margin-left: 82px;
  margin-right: 82px;
}

.hr {
  height: 2px;
  background-color: #d9d9d9;
  margin: auto;
  width: 122px;
  border: none;
}

.section2 {
  height: 80px;
  background: #3f4964;
}

.section1_4 {
  position: absolute;
  width: 1114px;
  height: 140px;
  bottom: -80px;
  background: #dbceab;
  display: flex;
  justify-content: space-between;
  padding: 50px 60px 20px 60px;
  text-transform: uppercase;
  color: #3f4964;
}

.section1_4_1 {
  margin-right: 20px;
}

.section1_4 h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.section1_4 p {
  font-size: 20px;
  line-height: 23px;
  margin-top: 16px;
}

.section1_4 a {
  font-size: 45px;
  line-height: 53px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
}

/* section2 */

.section3 {
  width: 100%;
  margin-bottom: -50px;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}

.section3_1 {
  width: 1396px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.text {
  margin-left: 10px;
  flex: 1 1 auto;
  padding-top: 80px;
  padding-left: 63px;
  padding-right: 65px;
  border: 1px solid #dbceab;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  text-align: justify;
  background: #fff;
}

.text p {
  margin-bottom: 30px;
  background-color: #fff;
}

.section3_1_img {
  flex: 0 0 545px;
  min-height: 690px;
}

.section3_1_img img {
  max-width: 100%;
  height: 100%;
}

.section3_2 {
  margin: 0 auto;
}

.hr2 {
  height: 2px;
  background-color: #d9d9d9;
  width: 122px;
  border: none;
  margin-top: 50px;
}

.signature {
  padding-left: 50%;
}

.signature h4 {
  margin-top: 48px;
  margin-bottom: 15px;
  font-family: "Sacramento";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 75%;
}

.section4 {
  /* height: 475px; */
  background: #dbceab;
  display: flex;
  flex-direction: column;
  padding-top: 163px;
  align-items: center;
}

.section4_1 H3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 36px;
}

.section4_2 {
  text-align: center;
  height: auto;
  width: 1396px;
  position: relative;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding-bottom: 50px;
}

.section4_2 .item {
  flex-basis: 33.33%;
  flex-grow: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  padding: 10px 20px;
}

.itemmiddle {
  border-style: solid;
  border-color: white;
  border-width: 0px 2px;
}

.tab {
  width: 1396px;
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}

.tab_textarea {
  height: auto;
  background-color: #e4ebf3;
  padding: 54px 64px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  margin-left: 10px;
  flex: 1 1 auto;
}

.tab_textarea button {
  margin-top: 35px;
  font-size: 25px;
  float: right;
}

.tab_controls {
  padding-top: 54px;
  flex: 0 0 545px;
  cursor: pointer;
}

.tab_controls_area {
  display: flex;
  align-items: flex-start;
  height: 150px;
  opacity: 0.5;
  text-align: left;
}

.tab_controls_area_active {
  opacity: 1;
}

.tab_controls_area_header {
  border-bottom: #a0a7b4 solid;
  padding-bottom: 35px;
  flex-grow: 1;
}

.tab_controls_area_header h2 {
  font-style: normal;
  color: #3f4964;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-align: justify;
  text-transform: uppercase;
}

.tab_controls_area_header p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  text-align: justify;
  color: #3f4964;
}

.tab_controls_area .number {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 33%;
  text-align: right;
  color: #dbceab;
  margin-right: 20px;
  margin-top: 10px;
}

.content {
  display: none;
}

.active-content {
  display: block;
}

.tab_texarea_accordion {
  display: none;
}

.contentAccordion {
  display: none;
}

@media screen and (max-width: 1396px) {
  .section3_1 {
    width: 90%;
  }
  .section4_1 {
    max-width: 70%;
  }
  .section4_2 {
    max-width: 90%;
  }
  .tab {
    max-width: 90%;
  }
}

@media screen and (max-width: 1350px) {
  .section1 {
    padding: 130px 0px 200px 0px;
  }
  .section1_4 {
    width: 90%;
    padding: 30px;
    height: auto;
  }
  .section1_4 a {
    font-size: calc(var(--index) * 1.6);
  }

  .section3_1 .text {
    padding: 30px 30px 0px 30px;
  }
}

@media screen and (max-width: 1300px) {
  .section1_3 {
    padding: 30px 0px 60px 0px;
  }

  .section3_1_img {
    flex: 0 0 432px;
  }
  .tab_controls {
    flex: 0 0 432px;
  }
  .tab_textarea {
    padding: 30px 30px 0px 30px;
  }
}

@media screen and (max-width: 1076px) {
  .section1 {
    padding: 50px 0px 150px 0px;
  }
  .section1_4 {
    flex-direction: column;
    text-align: center;
  }
  .signature {
    padding-left: 0;
  }

  .hr2 {
    margin-top: 20px;
  }

  .signature H4 {
    margin-top: 30px;
  }
}

@media screen and (max-width: 992px) {
  .tab_textarea {
    display: none;
  }
  .tab_controls {
    flex: 1 1 auto;
  }
  .tab_texarea_accordion {
    display: block;
    background-color: #fff;
    padding: 20px 0px 0px 0px;
  }
  .tab_controls_area_active {
    height: auto;
  }
  .tab {
    height: auto;
  }

  .tab_controls_area {
    height: auto;
  }

  .activeContentAccordion {
    display: block;
  }
  .tab_controls_area_header {
    border: none;
  }

  .section1_4_1 {
    margin-right: 0px;
  }
}

@media screen and (max-width: 960px) {
  .section3_1_img {
    display: none;
  }
  .section3_1 .text {
    padding: 80px 63px 80px 63px;
    margin-left: 0px;
  }

  .tab_controls_area .number {
    font-size: 70px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .section4_2 {
    flex-direction: column;
  }
  .section4_2 .item {
    padding: 20px;
  }
  .itemmiddle {
    border-width: 2px 0px;
  }
  .section1_3 h3 {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 35px;
    line-height: 45px;
  }
  .tab_controls_area .number {
    font-size: 50px;
  }
}

@media screen and (max-width: 672px) {
  .section1 {
    padding: 50px 0px 200px 0px;
  }
  .section1_1 {
    width: 90%;
  }
  .section3_1 .text {
    padding: 40px 30px 20px 30px;
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .section1_4 h3 {
    font-size: calc(var(--index) * 1.8);
  }
  .section1_4 p {
    font-size: calc(var(--index) * 1);
  }
  .section1_4 a {
    font-size: calc(var(--index) * 2.5);
  }
  .section1_4_2 {
    margin-top: 20px;
  }

  .tab_controls_area_header .activeContentAccordion {
    margin-left: -65px;
  }
}

@media screen and (max-width: 530px) {
  .section1_3 H3 {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 25px;
    line-height: 35px;
  }
  .section3_1 .text {
    font-size: 16px;
  }
}

.not_found {
  padding: 20px 70px;
}

.not_found h3 {
  margin-left: 20px;
  margin-right: 20px;
}

.not_found hr {
  margin-top: 15px;
}

.not_found h1 {
  margin-top: 20px;
}

.navBarTop {
  border-bottom: #fff solid 2px;
}
